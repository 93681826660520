<template>
  <div class="content">
    <!--        轮播图开始-->
    <div class="slide1">
      <swiper :options="swiperOption" class="swiper">
        <swiper-slide :key="index" class="swiperSlide" v-for="(item,index) in swiperList">
          <a :href="item.link" target="_blank"><img :src="$url+item.image" alt=""></a>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <!--        轮播图结束-->

    <!--        轮播图下面 格格背景-->
    <div class="slide2">

      <!--            企业介绍-->
      <div class="info" style="height:27.9792vw;">
        <div class="tuwen">
          <img alt="" :src="$url+aboutmeiamge">
          <div class="wen">
            <div style="width:100%;"><img class="team1" style="display:block;margin: 0 auto;width:117px;height:50px;"
                                          src="../assets/images/aboutus.png"></div>

            <p  style="margin-bottom:30px;margin-top:40px;white-space:pre-line;" v-html="aboutmetext" >
            </p>
          </div>
        </div>
      </div>

      <img class="company" src="../assets/img/team1.jpg">


      <div class="info1" style="margin-top:30px;">
        <div class="team" style="height:1vm;">
          <img style="width:145px;height:51px;" src="../assets/images/jingcai.png">
        </div>

        <el-carousel height="402px;" :interval="5000" arrow="always">
          <el-carousel-item v-for="item in showimg" :key="item">

            <div class="info_content" style="height:400px;width:100%;">
              <el-row>
                <el-image :src="item.image ? $url+item.image : '' " fit="cover" style="height:400px;width:100%;"/>

              </el-row>
            </div>
          </el-carousel-item>
        </el-carousel>


      </div>


      <video class="company1" id="startvideo" :src="$url+video" controls></video>


      <div class="info2" style="margin-top:60px;">
        <div class="tuwen">
          <div class="wen" style="margin:0 auto;">


            <div style="width:100%;text-align: center;margin-bottom:60px;"><img src="../assets/images/hezuo.png"
                                                                                style="width:197px;height:50px;"></div>

            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="姓名">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="电话">
                <el-input v-model="form.phone"></el-input>
              </el-form-item>
              <el-form-item label="咨询内容">
                <el-select v-model="form.region" placeholder="咨询内容">
                  <el-option label="承办赛事" value="0"></el-option>
                  <el-option label="报名参赛" value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="地区">
                <el-cascader
                    size="medium "
                    :options="options"
                    v-model="selectedOptions"
                    @change="handleChange">
                </el-cascader>
              </el-form-item>
              <el-form-item label="留言" style="height:110px;">
                <el-input type="textarea" style="height:110px;" rows="4" v-model="form.desc"></el-input>
              </el-form-item>
              <el-form-item style="margin-top:30px;">
                <el-button type="primary" size="medium"
                           style="display:block;width:50%;margin:0 auto;background:#01b0a0;border:1px solid #01b0a0;"
                           @click="onSubmit()">提交
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>


    <div class="callUs" v-if="hidden">
      <img :src="$url+xfctp" alt="">
      <div class='serve'>
        {{ xfc }}
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
// import Video from 'video.js'
import 'video.js/dist/video-js.css'
// import { Swiper, SwiperSlide } from 'swiper/vue';
// import { swiper, swiperSlide } from 'vue-awesome-swiper'
// Import Swiper styles
//import 'swiper/css';
import {regionData} from 'element-china-area-data'

export default {
  name: 'index',
  data() {
    return {
      swiperList: [],
      showimg: [],
      xfc: '',
      xfctp: '',
      swiperOption: {
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 允许点击小圆点跳转
        },
        autoplay: {
          delay: 5000,
        },

      },
      video: '',

      options: regionData,
      selectedOptions: [],
      swOption: {
        direction: 'vertical', // 垂直切换选项
        loop: true,
        slidesPerView: 3,
        autoplay: true,
      },
      inPeople: [],
      form: {
        name: '',
        phone: '',
        region: '',
        desc: '',
        province: '',
        city: '',
        area: '',
      },
      inPeoPleLength: 0,
      hidden: true,
      aboutmetext: '',
      aboutmeiamge: '',
      currentOffset: 0,
      width: "",
      fatherWith: 0,
      box: '', // dom
      swiperOption1: { // Swiper的参数配置
        direction: 'horizontal', // 水平方向轮播
        loop: true, // 循环播放
        autoplay: {
          delay: 5000, // 自动轮播时间间隔
          disableOnInteraction: false // 手动滑动后是否继续自动轮播
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        slidesPerView: 6, // 每次轮播几张卡片(可见个数)
        spaceBetween: 20, // 卡片之间的间隔
        centeredSlides: true // 将第一张卡片放在中间位置
      }

    }
  },
  mounted: function () {
  },
  components: {
    // swiper,
    // swiperSlide
    // SwiperSlide,
  },
  filters: {
    qian(num) {
      num = String(num)
      if (!/^(\+|-)?(\d+)(\.\d+)?$/.test(num)) {
        return num;
      }
      var a = RegExp.$1, b = RegExp.$2, c = RegExp.$3;
      var re = new RegExp().compile("(\\d)(\\d{3})(,|$)");
      while (re.test(b)) b = b.replace(re, "$1,$2$3");
      return a + "" + b + "" + c;
    },
    tel(str) {
      if (str) {
        return str.substring(0, 3) + '****' + str.substring(7, 11)
      }
    }
  },
  methods: {
    onSubmit() {
      if (this.form.name == "") {
        this.$message.error('姓名不能为空');
        return false
      } else if (this.form.phone == "") {
        this.$message.error('电话号不能为空');
        return false
      } else if (this.form.region == "") {
        this.$message.error('咨询内容不能为空');
        return false
      } else if (this.form.desc == "") {
        this.$message.error('留言内容不能为空');
        return false
      } else if (this.form.province == "") {
        this.$message.error('地区不能为空');
        return false
      }
      this.$post('index/Addcooperation', this.form)
          .then(res => {
            if (res.code == 1) {
              this.$message({
                showClose: true,
                message: '提交成功!',
                type: 'success'
              });
            }

          })
    },
    handleChange(value) {

      this.form.province = value[0]
      this.form.city = value[1]
      this.form.area = value[2]
    },
    //获取轮播图
    getBanner() {
      this.$get('index/banner')
          .then(res => {
            this.swiperList = res.data
          })
    },
    getshowimg() {
      this.$get('index/showimg')
          .then(res => {
            var data = res.data
            this.showimg = data
          })
    },
    //获取招商加盟

    getvideo() {
      this.$get('index/showvideo')
          .then(res => {
            this.video = res.data['image']
          })

    },
    aboutme() {
      this.$get('index/aboutme')
          .then(res => {
            this.aboutmetext = res.data['text']
            this.aboutmeiamge = res.data['image']
          })
    },
    getxfc() {
      this.$get('index/xfc')
          .then(res => {
            this.xfc = res.data[0].value ? res.data[0].value : "扫码关注"
            this.xfctp = res.data[1].value ? res.data[1].value : "/assets/images/qr.png"

          })

    },
    goPage() {
      this.$router.push({path: '/brief'})
    },
    newPage(url) {
      window.open(url)
    }
  },
  created() {
    this.getvideo()
    this.getxfc()
    this.getBanner()
    this.getshowimg()
    this.aboutme()
    this.selectedOptions = [this.form.provinceCode, this.form.cityCode, this.form.areaCode];

  },
  computed: {
    ...mapGetters(['info']),
    swiper() {
      return this.$refs.mySwiper.swiper
    }
  }

}
</script>

<style lang="less" scoped>
/deep/ .el-carousel__container {
  height: 400px;
}

/deep/ .el-carousel {
  height: 402px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

/deep/ .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%2301b0a0'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto;
}

/deep/ .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%2301b0a0'%2F%3E%3C%2Fsvg%3E");
  //width: 12%;
  //height: 6%;
  right: 10px;
  left: auto;
}

/deep/ .swiper-pagination-bullet-active {
  background: #fff;
}

///deep/ .swiper-pagination{
//  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L5%2C44l-4.2-4.2L18.6%2C22L0.8%2C4.2L5%2C0z'%20fill%3D'%2301b0a0'%2F%3E%3C%2Fsvg%3E");
//  width: 9%;
//  height: 6%;
//}
.team {
  height: 8vw;
  //width: 100%;
  display: block;
  margin: 0 auto;
  margin-top: 1.8792vw;
  margin-bottom: 1.4792vw;
}


/deep/ .el-select {
  display: block;
}

/deep/ .textarea__inner {
  height: 90px;
}

/deep/ .el-cascader {
  display: block;
}

.swiper1 {
  width: 100%;
  height: 200px;
  margin-bottom: 30px;
  //background: #999;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

video {
  object-fit: fill;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: 200px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

/deep/ .el-input__inner {
  height: 50px;
}

.content {
  width: 100%;
  min-height: 52.0833vw;

  .slide1 {
    width: 100%;
    height: 31.2500vw;
    background: #e8e7e7;

    .swiper, .swiperSlide, .swiperSlide img {
      width: 100%;
      height: 100%;
    }
  }

  .slide2 {
    width: 100%;
    min-height: 42.0833vw;
    //background: url("../assets/dong/bg1.png");
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5.0000vw;

    .info1 {
      width: 70vw;
      height: 630px;
      display: flex;
      flex-direction: column;

      .qiye {
        width: 100%;
        font-size: 2vm;
        height: 7.9167vw;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-size: 2.5521vw;
          font-weight: 550;
          letter-spacing: 0.7813vw;
          font-family: "SF Pro SC", "SF Pro Display", "SF Pro Icons", "PingFang SC", "Helvetica Neue", "Helvetica", "Arial", sans-serif !important;
          color: rgba(51, 51, 51, 0.96);
        }

      }
    }

    .info {
      width: 70vw;
      height: 36.9792vw;
      display: flex;
      flex-direction: column;

      .img1 {
        width: 27.0833vw;
        height: 4.5833vw;
      }

      .qiye {
        width: 100%;
        height: 7.9167vw;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-size: 2.5521vw;
          font-weight: 550;
          letter-spacing: 0.7813vw;
          font-family: "SF Pro SC", "SF Pro Display", "SF Pro Icons", "PingFang SC", "Helvetica Neue", "Helvetica", "Arial", sans-serif !important;
          color: rgba(51, 51, 51, 0.96);
        }

        button {
          width: 11.8750vw;
          height: 2.7083vw;
          background: #01b0a0;
          font-size: 0.9375vw;
          font-weight: 550;
          color: #FEFEFE;
          border-radius: 0.2083vw;
          letter-spacing: 0.2604vw;
          cursor: pointer;
          transition: all .5s;

        }

        button:hover {
          transform: scale(1.1);

        }
      }

      .tuwen {
        width: 100%;
        height: 18.6458vw;
        display: flex;

        img {
          width: 18.6458vw;
          height: 27.6458vw;
        }

        .wen {
          width: 51.3542vw;
          margin-left: 3.6458vw;

          p {
            text-indent: 2rem;
            font-size: 0.8333vw;
            color: #666666;
            line-height: 2.0833vw;
          }
        }
      }
    }

    .p_img {
      margin-block-start: 0;
      margin-block-end: 0;
      height: 133px;
    }

    .info2 {
      width: 34.5000vw;
      height: 600px;
      display: flex;
      flex-direction: column;

      .tuwen {
        width: 100%;
        height: 18.6458vw;
        display: flex;

        img {
          width: 18.6458vw;
          height: 27.6458vw;
        }

        .wen {
          width: 40.6250vw;
          margin-left: 3.6458vw;

          p {
            text-indent: 2rem;
            font-size: 0.8333vw;
            color: #666666;
            line-height: 2.0833vw;
          }
        }
      }
    }

    .orange {
      width: 100%;
      height: 10.4167vw;
      background: linear-gradient(49deg, #FF9D02, #FF6D00);
      box-shadow: 0.0000vw 0.6250vw 1.5104vw 0.0000vw rgba(0, 0, 0, 0.09);
      color: #ffffff;
      display: flex;
      justify-content: center;

      .msg {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .url {
          display: flex;
          align-items: flex-start;
          flex-direction: column;

          img {
            width: 1.4063vw;
            height: 1.0938vw;
            margin-right: 1.0938vw;
          }
        }

        .url p:nth-child(1) {
          font-size: 1.0417vw;
          letter-spacing: 0.2604vw;
          margin-bottom: 1.1979vw;
        }

        .url span {
          font-size: 0.8333vw;
          letter-spacing: 0.0521vw;
          line-height: 1.6667vw;
          border-bottom: 0.0521vw solid #ffffff;
          cursor: pointer;
        }

        .people {
          font-size: 1.1979vw;

          span {
            font-size: 3.4896vw;
            letter-spacing: 0.1042vw;
            margin: 0 0.7813vw;
          }

          margin: 0 4.1667vw 0 5.9375vw;
        }

        .list {
          p {
            display: flex;
            align-items: center;
            font-size: 1.0417vw;

            img {
              width: 1.3542vw;
              height: 1.3542vw;
              margin-right: 1.0417vw;
            }
          }

          .swiper {
            height: 3.8542vw;
          }

          > div {
            margin-top: 0.6250vw;
            width: 12.4167vw;
            height: 3.8542vw;
            overflow: hidden;

            swiperP, p {
              letter-spacing: 48;
              font-size: 0.7292vw;
              line-height: 1.0938vw;
              height: 1.0938vw;

              span {
                font-size: inherit;
                margin-left: 0.5208vw;
              }
            }
          }

        }

      }
    }

    .company {
      height: 6.1979vw;
      width: 70%;
      margin-top: 4.1146vw;
    }

    .company1 {
      height: 31.25vw;
      width: 70%;
      margin-top: 4.1146vw;
    }

    .job {
      width: 100%;
      height: 36.5104vw;
      display: flex;
      justify-content: center;
      align-items: center;

      .outer {
        width: 24.2708vw;
        height: 24.2708vw;
        margin: 0 4.0104vw;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 159, 168, 0.3);
        border-radius: 50%;
      }

      .circle {
        width: 23.4375vw;
        height: 23.4375vw;
        border-radius: 50%;
        background: url("../assets/dong/circle.png") no-repeat -8.8021vw 0;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        p {
          font-size: 2.9167vw;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 4.1667vw;
          letter-spacing: 0.7813vw;
        }

      }

      .left .item:nth-child(1) {
        margin-bottom: 3.6458vw;
      }

      .right .item:nth-child(1) {
        margin-bottom: 3.6458vw;
      }

      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;

        .item {
          display: flex;
          justify-content: flex-end;

          img {
            width: 3.8542vw;
            height: 3.8542vw;
            margin-left: 2.3438vw;
          }

          img:hover {
            transform: scale(1.2);
            transition: all 0.5s;
          }

          div {
            height: 3.8542vw;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-around;

            h5 {
              font-size: 1.2500vw;
              font-weight: bold;
              color: #333333;
            }

            span {
              font-size: 0.8333vw;
              font-weight: 550;
              color: #999999;
              margin-top: 1.0417vw;

            }
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .item {
          display: flex;
          justify-content: flex-end;

          img {
            width: 3.8542vw;
            height: 3.8542vw;
            margin-right: 2.3438vw;
          }

          img:hover {
            transform: scale(1.2);
            transition: all 0.5s;
          }

          div {
            height: 3.8542vw;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-around;

            h5 {
              font-size: 1.2500vw;
              font-weight: bold;
              color: #333333;
            }

            span {
              font-size: 0.8333vw;
              font-weight: 550;
              color: #999999;
              margin-top: 1.0417vw;

            }
          }
        }
      }
    }
  }
}

.callUs {
  transition: all 0.5s;
  width: 8.9271vw;
  height: 8.9271vw;
  background: #FFFFFF;
  box-shadow: 0.0000vw 0.3646vw 1.2500vw 0.0000vw rgba(0, 0, 0, 0.19);
  border-radius: 1.0417vw;
  padding-top: 7.2188vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  position: fixed;
  z-index: 100;
  //right: 0;
  left: 0;
  // 4.4271vw;
  top: 35.4167vw;

  > img {
    width: 7.4896vw;
    height: 6.4896vw;
    position: absolute;
    left: 0.6188vw;
    top: 1.2188vw;

  }

  .serve {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8333vw;
    font-weight: 550;
    color: #333333;

    :nth-child(1) {
      margin-right: 0.5208vw;
      height: 0.9375vw;
      width: 0.9375vw;
    }
  }

  .tel {
    width: 8.5938vw;
    height: 2.0208vw;
    margin-top: 0.9375vw;
    background: #FF5501;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    flex-direction: column;

    :nth-child(1) {
      font-size: 0.992vw;
    }

    p {
      font-size: 0.9375vw;
    }

  }
}
</style>
